// 'dev' : 개발 서버
// 'dev2' : local
// 'prod' : 운영서버 (올포랜드)
const ENV = 'prod';

// const ENV_DEV = "dev";
// const ENV_DEV2 = "dev2";
// const ENV_PROD = "prod";

const BACKEND_DEV = 'http://192.168.10.118:3005'; //118
const BACKEND_DEV2 = 'http://localhost:3005';
const BACKEND_PROD = 'https://drone-platform.kr';

const GEOSVR_PROXY = '/geoserver';

const GEOSVR_DEV = 'http://192.168.10.118:30080/geoserver'; //118
const GEOSVR_DEV2 = 'http://192.168.10.118:30080/geoserver'; //118
const GEOSVR_PROD = 'https://drone-platform.kr/geoserver';

const VWORLD_URL = 'https://api.vworld.kr/req/wmts/1.0.0';
// const VWORLD_KEY = "3561622C-C0C0-3DE8-A36C-546D9AB651FB";
// const VWORLD_KEY = "7DCA9EE4-8B2F-332B-A755-C3AFF4FAECB6";
const VWORLD_KEY = '34BDF60E-7788-3785-81BC-463ABD747637';

const META_SYSTEM_URL = 'https://meta.drone-platform.kr';
const META_SYSTEM_URL_DEV = 'http://192.168.10.118:8000';

const META_SYSTEM_API = 'meta.drone-platform.kr';
const META_SYSTEM_API_DEV = '192.168.10.118:8000';

// default : dev
const config = {
  env: ENV,
  backend:
    ENV == 'dev2' ? BACKEND_DEV2 : ENV == 'dev' ? BACKEND_DEV : BACKEND_PROD,
  geoServer: ENV == 'dev' || ENV == 'dev2' ? GEOSVR_DEV : GEOSVR_PROD,
  geoServerProxy: GEOSVR_PROXY,
  vworldSat: VWORLD_URL + '/' + VWORLD_KEY + '/Satellite/{z}/{y}/{x}.jpeg',
  vworldKey: VWORLD_KEY,
  metaSystemUrl:
    ENV == 'dev' || ENV == 'dev2' ? META_SYSTEM_URL_DEV : META_SYSTEM_URL,
  metaSystemAPI:
    ENV == 'dev' || ENV == 'dev2' ? META_SYSTEM_API_DEV : META_SYSTEM_API,
};

// set other env
//config.env = ENV_DEV2;

// if (config.env == ENV_PROD) {
//   config.backend = BACKEND_PROD;
//   config.geoServer = GEOSVR_PROD;
// }
// if (config.env == ENV_DEV2) {
//   config.backend = BACKEND_DEV2;
//   config.geoServer = GEOSVR_DEV2;
// }

module.exports = config;
